import api from '@/api'
import * as storage from '@/storage'
import { omit, omitBy } from 'lodash'
import { isFuture, addMinutes, parse } from 'date-fns'

import { dataURLtoBlob } from '@/utils/data-url'
import { jsonToFormData } from '@/utils/formdata'
import { toTokyoTimezone } from '@/utils/format-date'

const state = {
    list: null,
    tags: [],
    pos_list: [],
    passes: {},
    passes_sum: null,
}

const getters = {
    get_project_passes_updated: () => id => {
        let date = storage.local.getItem('project.' + id)
        if (!date) return false

        if (isFuture(date)) {
            return parse(date)
        }

        storage.local.removeItem('project.' + id)
        return false
    },
}

const mutations = {
    RESET(state) {
        state.list = []
        state.tags = []
        state.passes = {}
        state.passes_sum = null
    },
    SET_PROJECTS(state, data) {
        state.list = data
        state.passes = {}
        state.passes_sum = null
    },
    SET_PROJECT_TAGS(state, data) {
        state.tags = data
    },

    SET_PROJECT_PASSES(state, data) {
        state.passes = data
    },
    SET_PASSES_SUM(state, data) {
        state.passes_sum = data
    },
    RESET_PROJECT_PASSES(state) {
        state.passes = {}
        state.passes_sum = null
    },
}

const actions = {
    createTicketProject({ dispatch, commit }, project) {
        project.type = project.type ? project.type : 'event' // fallback to event
        return dispatch('createProject', project).then(data => {
            dispatch('getUser')
            commit('SET_PROJECTS')
            return data
        })
    },

    getProjects({ commit, dispatch }, params = {}) {
        let { pagination, search, filters, archived, type, subtype } = params
        const options = { ...pagination, search, filters: JSON.stringify(filters), subtype }
        if (typeof archived !== 'undefined') {
            options.archived = archived
        }

        return api
            .get('/projects/list/' + (type ? `${type}` : 'all'), { params: options })
            .then(data => {
                commit('SET_PROJECTS', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },

    getLoyaltyPrograms({ dispatch }, params = {}) {
        let { pagination, search, filters, archived } = params
        const options = { ...pagination, search, filters }
        if (typeof archived !== 'undefined') {
            options.archived = archived
        }

        return dispatch('getProjects', { ...params, type: 'loyalty' })
    },

    getCoupons({ dispatch }, params = {}) {
        let { pagination, search, filters, archived } = params
        const options = { ...pagination, search, filters }
        if (typeof archived !== 'undefined') {
            options.archived = archived
        }

        return dispatch('getProjects', { ...params, type: 'coupon' })
    },

    getProject({ commit, dispatch }, id) {
        return api
            .get(`/projects/${id}`)
            .then(({ data }) => {
                commit('RESET_PROJECT_PASSES')

                return data
            })
            .catch(e => dispatch('handleError', e))
    },

    createProject({ dispatch }, project) {
        return api
            .request({
                url: '/projects',
                method: 'post',
                data: { project: serializeProject(project) },
            })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    updateProject({ dispatch }, project) {
        return api
            .request({
                url: `/projects/${project.id}`,
                method: 'put',
                data: { project: serializeProject(project) },
            })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    updateProjectImages({ dispatch }, { project, data }) {
        if (
            !Object.keys(data || {}).length ||
            (data.images &&
                Object.keys(data).length === 1 &&
                !Object.keys(data?.images || {}).length)
        ) {
            return Promise.resolve()
        }

        const formData = new FormData()

        for (const key in data) {
            if (key === 'images') {
                const encodedImages = omitBy(
                    data.images,
                    v => !(typeof v == 'string' && v.indexOf('data:') == 0)
                )

                let images = Object.keys(encodedImages).reduce(
                    (images, key) => ({ ...images, [key]: dataURLtoBlob(encodedImages[key]) }),
                    {}
                )
                formData.append(
                    'project[image_states][wallpaper]',
                    typeof data.images.wallpaper !== 'string' ? 'removed' : 'present'
                )

                for (const key in images) {
                    formData.append(`project[images][${key}]`, images[key])
                }
            } else {
                formData.append(`project[${key}]`, data[key])
            }
        }

        return api
            .request({
                url: `/projects/${project.id}/images`,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
            })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },

    patchProject({ dispatch }, { id, data }) {
        return api
            .patch(`/projects/${id}`, { project: data })
            .catch(e => dispatch('handleError', e))
    },

    deleteProject({ dispatch }, id) {
        return api.delete(`/projects/${id}`).catch(e => dispatch('handleError', e))
    },

    archiveProject({ dispatch }, id) {
        return api.patch(`/projects/${id}/archive`).catch(e => dispatch('handleError', e))
    },
    unarchiveProject({ dispatch }, id) {
        return api.patch(`/projects/${id}/unarchive`).catch(e => dispatch('handleError', e))
    },

    publishProject({ dispatch }, project) {
        if (project.type === 'booking' && !project?.units?.length) {
            dispatch('handleError', 'You need to add a booking unit before publishing.')

            setTimeout(() => dispatch('resetError'), 3000)

            throw new Error('You need to add a booking unit before publishing.')
        }
        if (
            (Object.keys(project.form_fields).length ||
                project.has_registration ||
                project.has_data_collection) &&
            !project.link_to_privacy &&
            !project.privacy &&
            project.subtype !== 'hotel_pass'
        ) {
            dispatch('handleError', 'You need to add privacy policy when data collection is on.')

            setTimeout(() => dispatch('resetError'), 3000)

            throw new Error('You need to add privacy policy when data collection is on.')
        }

        return api.patch(`/projects/${project.id}/publish`).catch(e => dispatch('handleError', e))
    },

    unPublishProject({ dispatch }, project) {
        return api.patch(`/projects/${project.id}/unpublish`).catch(e => dispatch('handleError', e))
    },

    cloneProject({ dispatch }, id) {
        return api
            .post(`/projects/${id}/clone`, {})
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },

    getProjectContacts({ dispatch }, { id, pagination, search }) {
        return api
            .get(`/projects/${id}/contacts`, { params: { ...pagination, search } })
            .catch(e => dispatch('handleError', e))
    },

    getTimeTrackers({ dispatch }, { id, pagination, is_company, interval, start, end }) {
        return api
            .get(`/projects/${id}/time-trackers`, {
                params: { ...pagination, is_company, interval, start, end },
            })
            .catch(e => dispatch('handleError', e))
    },

    getProjectActivity({ dispatch }, { id, pagination, filters = {} }) {
        if (!id) {
            return Promise.resolve()
        }
        pagination ? delete pagination.totalItems : false
        const definedFilters = omitBy(filters, v => [null, undefined].includes(v))

        return api
            .get(`/projects/${id}/activity`, {
                params: { ...definedFilters, ...pagination },
            })
            .catch(e => dispatch('handleError', e))
    },
    exportProjectActivity({ dispatch }, { id, filters = {}, selectedColumns = [] }) {
        if (!id) {
            return Promise.resolve()
        }
        const definedFilters = omitBy(filters, v => [null, undefined].includes(v))

        return api
            .post(`/projects/${id}/activity/export`, {
                ...definedFilters,
                selectedColumns,
            })
            .catch(e => dispatch('handleError', e))
    },
    exportProjectPasses({ dispatch }, params = {}) {
        let { id, filters = {}, selectedColumns = null } = params
        const definedFilters = omitBy(filters, v => [null, undefined].includes(v))
        return api
            .post(`/projects/${id}/passes/export`, {
                ...definedFilters,
                selectedColumns,
            })
            .catch(e => dispatch('handleError', e))
    },
    refreshProjectPasses({ dispatch }, id) {
        return api
            .post(`/projects/${id}/refresh`, {})
            .then(() => {
                storage.local.setItem('project.' + id, addMinutes(parse(new Date()), 10))
            })
            .catch(e => dispatch('handleError', e))
    },

    syncFares({ dispatch }, { projectId, data }) {
        const filteredData = data.map(value => {
            return {
                id: value.id,
                origin_id: value.origin_id,
                destination_id: value.destination_id,
                plans: value.plans,
                regular_price: value.regular_price,
            }
        })

        return api
            .post(`/fares/${projectId}`, { fares: filteredData })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    syncStations({ dispatch }, { projectId, data }) {
        return api
            .request({
                url: `/stations/${projectId}`,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: jsonToFormData({ stations: serializeStations(data) }),
            })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    generatePurolandPrices({ dispatch }, { id }) {
        return api
            .post(`/projects/${id}/puroland`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },

    toggleProjectSales({ dispatch }, id) {
        return api.patch(`/projects/${id}/sales`).catch(e => dispatch('handleError', e))
    },
    fetchProjectSeatMap(_, { projectId, venueId, ...data }) {
        return api.get(`/projects/${projectId}/seatmap/${venueId}`, { params: data })
    },
    blockSeatOnMap(_, { projectId, venueId, seat }) {
        return api.put(`/projects/${projectId}/blockseat/${venueId}`, seat)
    },
    unblockSeatOnMap(_, { projectId, venueId, seat }) {
        return api.put(`/projects/${projectId}/unblockseat/${venueId}`, seat)
    },
    getPassLinks({ dispatch }, params = {}) {
        const { id, pagination, search, is_expired } = params
        pagination ? delete pagination.totalItems : false
        return api
            .get(`/projects/${id}/urls`, { params: { ...pagination, search, is_expired } })
            .catch(e => dispatch('handleError', e))
    },
    createShortUrl({ dispatch }, { id, data }) {
        return api.post(`/projects/${id}/urls`, data).catch(e => dispatch('handleError', e))
    },
    deleteShortUrl({ dispatch }, { id, url_id }) {
        return api.delete(`/projects/${id}/urls/${url_id}`).catch(e => dispatch('handleError', e))
    },
    exportShortUrls({ dispatch }, params = {}) {
        const { id, selectedColumns = null, is_expired } = params
        return api
            .post(`/projects/${id}/urls/export`, {
                selectedColumns,
                is_expired,
            })
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
    exportShortUrlCodes({ dispatch }, { id, url_id }) {
        return api
            .post(`/projects/${id}/urls/${url_id}/codes`, {})
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
    printShortUrls({ dispatch }, params = {}) {
        const { id, selectedColumns = null, is_expired } = params
        return api
            .post(`/projects/${id}/urls/print`, {
                selectedColumns,
                is_expired,
            })
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
    importShortUrls({ dispatch }, params = {}) {
        const { file, id } = params
        const formData = new FormData()

        let url = `/projects/${id}/urls/import`

        formData.append('file', file)

        return api
            .request({
                url,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
            })
            .catch(e => dispatch('handleError', e))
    },
    exportProjectsActivity({ dispatch }, { type, projects, filters = {} }) {
        const definedFilters = omitBy(filters, v => [null, undefined].includes(v))

        return api
            .post(`/projects/activity/${type}/export`, { projects, ...definedFilters })
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
    getProjectsTags({ commit, dispatch }) {
        return api
            .get('/projects/tags/list')
            .then(({ tags }) => {
                commit('SET_PROJECT_TAGS', tags)
                return tags
            })
            .catch(e => dispatch('handleError', e))
    },
    getProjectLotteryTickets({ dispatch }, params) {
        const { id, pagination, filters } = params
        pagination ? delete pagination.totalItems : false

        return api
            .get(`/projects/${id}/lottery-tickets`, { params: { ...pagination, filters } })
            .catch(e => dispatch('handleError', e))
    },
    resetProjects({ commit }) {
        commit('SET_PROJECTS')
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}

function serializeStations(stations) {
    stations.forEach(s => {
        if (s.image && s.image.indexOf('data:') === 0) {
            s.image = dataURLtoBlob(s.image)
        } else {
            delete s.image
        }
    })

    return stations
}

function serializeProject(project) {
    let venues = project.venues || []

    const data = omit(project, [
        'template_id',
        'user_id',
        'short_url_id',
        'shortlink',
        'link',
        'dashboard_link',
        'slug',
        'barcode_type',
        'barcode_format',
        'discount_terms',
        'shortuuid',
        'is_enabled',
        'is_published',
        'has_activity',
        'remote_id',
        'template_status',
        'template_archived_at',
        'created_at',
        'updated_at',
        'published_at',
        'archived_at',
        'payment_type',
        'title',
        'titles',
        'venues',
        'template',
        'plans',
        'fares',
        'stations',
        'start',
        'end',
        'kv_tags',
        'total_installed',
        'total_issued',
        'total_registrations',
        'total_uninstalled',
        'total_used',
        'total_views',
        'total_visitors',
        'ios_installed',
        'ios_uninstalled',
        'android_installed',
        'android_uninstalled',
        'passes_count',
        'times_redeemed',
        'walletpasses_paid_count',
        'walletpasses_installed_count',
    ])

    if (venues && venues.length) {
        venues = venues.map(venue => venue.id || venue)
    }

    if (data.start) data.start = toTokyoTimezone(data.start)
    if (data.expires_at) data.expires_at = toTokyoTimezone(data.expires_at)

    return { ...data, venues }
}
