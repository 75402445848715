<script>
export default {
    name: 'k-text-field',
    inheritAttrs: false,
    props: {
        persistentPlaceholder: { type: Boolean, require: false, default: false },
        outlined: Boolean,
    },
}
</script>

<template>
    <v-text-field
        v-bind="$attrs"
        v-on="$listeners"
        class="k-text-field"
        :class="{ 'k-text-field--persistent': persistentPlaceholder }"
        :persistent-placeholder="persistentPlaceholder"
        :outlined="outlined"
        background-color="white"
    >
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </v-text-field>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/settings/_variables';

.k-text-field::v-deep {
    .v-label {
        text-transform: capitalize;
    }
}

.k-text-field--persistent::v-deep {
    input::placeholder {
        color: #000;
        text-transform: capitalize;
    }
}
</style>
