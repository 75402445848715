import api from '@/api'

const state = {
    plans: [],
    invoices: [],
}

const getters = {}

const mutations = {
    RESET(state) {
        state.plans = []
        state.invoices = []
    },
    SET_KINCHAKU_PLANS(state, data) {
        state.plans = data
    },
    SET_INVOICES(state, data) {
        state.invoices = data
    },
}

const actions = {
    saveSubscription({ dispatch }, plan) {
        return api.post('/billing', { plan }).catch(e => dispatch('handleError', e))
    },

    cancelSubscription({ dispatch }) {
        return api.delete('/billing/cancel', {}).catch(e => dispatch('handleError', e))
    },

    resumeSubscription({ dispatch }) {
        return api.patch('/billing/resume', {}).catch(e => dispatch('handleError', e))
    },
    getInvoices({ commit, dispatch }) {
        return api
            .get('/billing/invoices')
            .then(({ invoices }) => {
                commit('SET_INVOICES', invoices)
            })
            .catch(e => dispatch('handleError', e))
    },
    checkSubscriptionCoupon({ dispatch }, coupon) {
        return api
            .post('/billing/coupon', { coupon })
            .then(({ coupon }) => {
                return coupon
            })
            .catch(e => dispatch('handleError', e))
    },
    applyGiftCode({ dispatch }, coupon) {
        return api
            .post('/billing/giftcode', { coupon })
            .then(({ data }) => {
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    setupIntent({ dispatch }) {
        return api
            .post('/billing/setup', {})
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    createBankSubscription({ dispatch }, plan) {
        return api
            .post(`/billing/bank/${plan}`, {})
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getPortalURL({ dispatch }) {
        return api
            .post('/billing/portal', {})
            .then(({ data }) => {
                return data
            })
            .catch(e => dispatch('handleError', e))
    },

    addVenueItem({ dispatch }, quantity) {
        return api.patch('/billing/venues', { quantity }).catch(e => dispatch('handleError', e))
    },

    removeVenueItem({ dispatch }) {
        return api.delete('/billing/venues').catch(e => dispatch('handleError', e))
    },

    addPlanItem({ dispatch }, params) {
        return api.patch('/billing/items', params).catch(e => dispatch('handleError', e))
    },

    removePlanItem({ dispatch }, params) {
        return api.post('/billing/items/remove', params).catch(e => dispatch('handleError', e))
    },

    exportSummaryAsCSV({ dispatch }, year) {
        return api.post('/billing/summary/export', { year }).catch(e => dispatch('handleError', e))
    },

    exportDetailedAsCSV({ dispatch }, date) {
        return api.post('/billing/detailed/export', { date }).catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    getters,
    mutations,
}
