import Vue from 'vue'
import Vuex from 'vuex'
import { setMapOptions } from 'vue-mapkit'

import auth from './modules/auth'
import campaigns from './modules/campaigns'
import contactLists from './modules/contactLists'
import workspaces from './modules/workspaces'
import billing from './modules/billing'
import account from './modules/account'
import designs from './modules/designs'
import integrations from './modules/integrations'
import contacts from './modules/contacts'
import uploads from './modules/uploads'
import projects from './modules/projects'
import products from './modules/products'
import plans from './modules/plans'
import coupons from './modules/coupons'
import vouchers from './modules/vouchers'
import connect from './modules/connect'
import invoices from './modules/invoices'
import transactions from './modules/transactions'
import templates from './modules/templates'
import navigation from './modules/navigation'
import metrics from './modules/metrics'
import messages from './modules/messages'
import notificationActions from './modules/actions'
import passes from './modules/passes'
import venues from './modules/venues'
import kyc from './modules/kyc'
import rewards from './modules/rewards'
import bookings from './modules/bookings'
import transport from './modules/transport'
import translations from './modules/translations'
import announcements from './modules/announcements'
import ai from './modules/ai'
import router from '@/router'
import i18n from '@/utils/vue-i18n'
import api from '@/api'
import * as storage from '@/storage'

Vue.use(Vuex)
const state = {
    loading: false,
    error: null,
    errorPage: null, //{code: "500", message: "Some error message"}
    mapKitToken: null,
}

const mutations = {
    SET_LOADING(state, data) {
        state.loading = data
    },
    SET_ERROR(state, data) {
        state.error = data
    },
    SET_ERROR_PAGE(state, data) {
        state.errorPage = data
    },
    SET_MAP_KIT_TOKEN(state, token) {
        state.mapKitToken = token
    },
}

const actions = {
    startLoading({ commit }) {
        commit('SET_LOADING', true)
    },

    finishLoading({ commit }) {
        commit('SET_LOADING', false)
    },

    loadMapKitToken({ dispatch, commit, state }) {
        if (state.mapKitToken) {
            dispatch('setMapKitToken', state.mapKitToken)
            return Promise.resolve()
        }

        return api
            .post('/mapkit/token')
            .then(({ data }) => {
                if (data?.token) {
                    commit('SET_MAP_KIT_TOKEN', data.token)
                    dispatch('setMapKitToken', data.token)
                } else {
                    // eslint-disable-next-line no-console
                    console.warn('Failed to get map token')
                }
            })
            .catch(e => {
                // eslint-disable-next-line no-console
                console.warn('Failed to get map token', e)
            })
    },

    setMapKitToken(_, token) {
        setMapOptions({
            authorizationCallback: function (done) {
                done(token)
            },
        })
    },

    handleError({ commit, dispatch }, error) {
        let status = 0
        if (typeof error !== 'string') {
            status = error.response ? error.response.status : 0
            if (error == 401) {
                dispatch('clearSession')
                if (router.currentRoute.path !== '/signin') router.push('/signin')
                return Promise.reject(status)
            } else if (!error.response) {
                // eslint-disable-next-line
                console.error(error)
                error = 'Request failed'
            } else if (
                error.response &&
                (error.response.status == 401 || error.response.status == 403)
            ) {
                error = 'Access Denied'
            } else if (
                error.response &&
                error.response.data.errors &&
                error.response.data.errors.message
            ) {
                error = error.response.data.errors.message
            } else if (
                // stripe-style errors
                error.response &&
                error.response.data.error &&
                error.response.data.error.message
            ) {
                error = error.response.data.error.message
            } else if (error.response && error.response.data.errors) {
                var errorString = ''
                for (var prop in error.response.data.errors) {
                    errorString += error.response.data.errors[prop] + '\n'
                }
                error = errorString
            } else if (error.response && error.response.data.message) {
                error = error.response.data.message
            } else {
                error = 'Request failed'
            }
        }

        if (
            typeof error === 'string' &&
            i18n.existsTranslation(storage.local.getItem('app.language'), error)
        ) {
            error = i18n.translate(storage.local.getItem('app.language'), error)
        }

        commit('SET_ERROR', error)
        if (status) return Promise.reject(status) // Passes error only for API errors
    },
    resetError({ commit }) {
        commit('SET_ERROR', null)
    },
}
export default new Vuex.Store({
    state,
    actions,
    mutations,
    modules: {
        auth,
        workspaces,
        billing,
        account,
        designs,
        uploads,
        contacts,
        integrations,
        projects,
        products,
        plans,
        connect,
        coupons,
        vouchers,
        invoices,
        transactions,
        templates,
        navigation,
        metrics,
        campaigns,
        contactLists,
        messages,
        notificationActions,
        passes,
        venues,
        kyc,
        bookings,
        transport,
        rewards,
        translations,
        announcements,
        ai,
    },
})
