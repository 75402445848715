import api from '@/api'
import { pick } from 'lodash'

import { PROJECT_TYPES } from '@/constants/projects'

const state = {
    list: [],
    members: null,
    joined_workspaces: [],
    drawer: true,
    drawer_mini: false,
    project_types: [
        {
            link: '/projects/?type=loyalty&subtype=stamp',
            icon: 'loyalty',
            title: 'stamp cards',
            type: 'stamp',
        },
        {
            link: '/projects/?type=loyalty&subtype=member',
            icon: 'card_membership',
            title: 'member cards',
            type: 'member',
            pro: true,
        },
        {
            link: '/projects/?type=coupon',
            icon: 'local_offer',
            title: 'coupons',
            type: 'coupon',
        },
        {
            link: '/projects/?type=ticket',
            icon: 'confirmation_number',
            title: 'tickets',
            type: 'ticket',
            types: ['event', 'admission'],
        },
        {
            link: '/products/vouchers',
            icon: 'local_mall',
            title: 'vouchers',
            type: 'voucher',
        },
        {
            link: '/products/subscriptions',
            icon: 'blur_linear',
            title: 'subscriptions',
            type: 'service',
        },
        {
            link: '/products/bookings',
            icon: 'event_available',
            title: 'bookings',
            type: 'booking',
            pro: true,
        },
        {
            link: '/products/transport',
            icon: 'commute',
            title: 'transport',
            type: 'transport',
            hidden: true,
            pro: true,
        },
    ],
}

const getters = {
    enabled_projects: ({ project_types }, { workspace_flags, on_trial }) => {
        const is_dev = import.meta.env.NODE_ENV !== 'production'

        // show all projects on trial (without hidden/dev only)
        if (on_trial) return project_types.filter(v => !v.hidden || is_dev)

        let { disabled_projects, unlocked_projects } = workspace_flags
        disabled_projects = disabled_projects || []
        unlocked_projects = unlocked_projects || []

        let unlocked = project_types.filter(
            v => !v.hidden || unlocked_projects.indexOf(v.type) != -1
        )

        return unlocked.filter(project => disabled_projects.indexOf(project.type) === -1) || []
    },
    is_project_type_enabled:
        (state, { enabled_projects }, { workspace_flags }) =>
        type => {
            if (type === 'campaign') return !!workspace_flags?.with_campaign
            return !!enabled_projects.find(p => p.type === type)
        },

    is_upgrade_needed:
        (state, getters, { account }) =>
        ({ type, subtype }, is_new) => {
            if (!type || type === 'campaign') return false

            const allowedProjectsByPlan = account?.user?.allowed_projects || {}

            if (!Object.keys(allowedProjectsByPlan).length) return true

            const isAllowed = Object.prototype.hasOwnProperty.call(allowedProjectsByPlan, subtype)
                ? allowedProjectsByPlan[subtype]
                : allowedProjectsByPlan[
                      // voucher active if coupon is active because coupon can transform to voucher by adding a plan
                      type === PROJECT_TYPES.VOUCHER ? PROJECT_TYPES.COUPON : type
                  ]
            if (getters.subscribed_smb) {
                return !((getters.allowed_to_create_project || !is_new) && isAllowed)
            }

            return !isAllowed
        },
}

const mutations = {
    RESET(state) {
        state.list = []
        state.joined_workspaces = []
        state.workspace = {}
        state.drawer = true
        state.drawer_mini = false
        state.members = null
    },
    SET_WORKSPACES(state, data) {
        state.list = data
    },
    SET_JOINED_WORKSPACES(state, data) {
        state.joined_workspaces = data
    },
    SET_WORKSPACE_DRAWER(state, data) {
        state.drawer = data
    },
    SET_WORKSPACE_DRAWER_MINI(state, data) {
        state.drawer_mini = data
    },
    SET_MEMBERS(state, data) {
        state.members = data
    },
}

const actions = {
    createWorkspace({ dispatch }, workspace) {
        return api
            .post('/workspaces', { workspace })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    updateWorkspace({ getters, dispatch }, { name, settings }) {
        var formData = new FormData()

        formData.append('settings[name]', name)
        let toPick = [
            'enable_notifications',
            'enable_email_registration',
            'is_encrypted',
            'currency',
            'brand_name',
            'sender_address',
            'hide_kinchaku_branding',
            'custom_domain',
            'icon_file',
            'logo_file',
            'credit_balance_limit',
            'terms_link',
            'privacy_link',
            'colors',
        ]

        let data = pick(settings, toPick)

        for (var key in data) {
            const val = data[key]
            if (key === 'colors') {
                Object.keys(val).forEach(l => {
                    formData.append(`settings[${key}][${l}]`, val[l] === null ? '' : val[l])
                })
            } else {
                formData.append(`settings[${key}]`, val === null ? '' : val)
            }
        }
        let headers
        if (data.icon_file || data.logo_file) {
            headers = { 'Content-Type': 'multipart/form-data' }
        } else {
            if (getters.is_kinchaku_staff) {
                toPick.push('flags')
            }
            formData = { settings: { ...pick(settings, toPick), name } }
        }

        return api
            .request({
                url: '/workspaces/settings',
                method: 'post',
                headers,
                data: formData,
            })
            .catch(e => dispatch('handleError', e))
    },
    updateWorkspaceEmail({ dispatch }, email) {
        return api
            .patch('/workspaces/email/request', {
                email,
            })
            .catch(e => dispatch('handleError', e))
    },
    confirmWorkspaceEmail({ dispatch }, token) {
        return api
            .patch(`/workspaces/email/confirm/${token}`)
            .catch(e => dispatch('handleError', e))
    },
    resendInvite({ dispatch }, id) {
        return api.patch(`/invites/${id}/resend`).catch(e => dispatch('handleError', e))
    },
    deleteWorkspace({ dispatch }, reason) {
        return api
            .delete(`/workspaces`, { data: { reason } })
            .catch(e => dispatch('handleError', e))
    },
    denyWorkspaceInvite({ dispatch }, token) {
        return api
            .patch(`/invites/${token}/deny`)
            .then(data => data.message)
            .catch(e => dispatch('handleError', e))
    },
    checkHasSupport({ dispatch }) {
        return api
            .get('/workspaces/has/support')
            .then(data => data)
            .catch(e => dispatch('handleError', e))
    },
    getWorkspaces({ getters, commit, dispatch }) {
        return api
            .get(getters.has_context ? '/workspaces/list' : '/workspaces/')
            .then(({ data }) => {
                commit('SET_WORKSPACES', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    getWorkspaceInvites({ dispatch }, { search, pagination, status }) {
        const params = { ...pagination, search, status }

        return api
            .get('/invites', { params })
            .then(data => data)
            .catch(e => dispatch('handleError', e))
    },
    getMembersList({ commit, dispatch }) {
        return api
            .get('/workspaces/members/list')
            .then(({ data }) => {
                commit('SET_MEMBERS', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    getMembers({ dispatch }, { search, pagination, venue }) {
        const params = { ...pagination, search, venue }
        return api.get('/workspaces/members', { params }).catch(e => dispatch('handleError', e))
    },
    getMembersCount({ dispatch }) {
        return api
            .get('/workspaces/members/count')
            .then(({ count }) => count)
            .catch(e => dispatch('handleError', e))
    },
    addMembers({ dispatch }, member) {
        return api
            .post('/workspaces/members', { member })
            .then(data => data)
            .catch(e => dispatch('handleError', e))
    },
    removeMember({ dispatch }, id) {
        return api.delete(`/workspaces/members/${id}`, {}).catch(e => dispatch('handleError', e))
    },
    removeInvite({ dispatch }, id) {
        return api.delete(`/invites/${id}`).catch(e => dispatch('handleError', e))
    },
    changeMemberRole({ dispatch }, { id, role }) {
        return api
            .patch(`/workspaces/members/${id}/update/${role}`)
            .catch(e => dispatch('handleError', e))
    },

    getOperatorVenues({ dispatch }, id) {
        return api
            .get(`/workspaces/members/${id}/venues`, {})
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    updateOperatorSettings({ dispatch }, { id, single_session }) {
        return api
            .patch(`/workspaces/members/${id}/settings`, { single_session })
            .catch(e => dispatch('handleError', e))
    },
    savePaymentMethod({ dispatch }, payment_method) {
        return api
            .post('/workspaces/paymentmethods', {
                payment_method,
            })
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    getters,
    mutations,
}
