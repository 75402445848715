import api from '@/api'

const state = {}

const mutations = {}

const actions = {
    askAI({ dispatch }, { operation, input }) {
        return api
            .post('ai', { operation, input })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
